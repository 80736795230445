const TEXT_TYPE = 'TEXT'

export default class SceneItem {
  constructor({
    type = TEXT_TYPE,
    parentId = 'canvas',
    content = null,
    zIndex = 1
  }) {
    this.id = SceneItem.getUniqId()
    this.type = type
    this.children = []
    this.content = content
    this.parentId = parentId
    this.zIndex = zIndex
    this.icon = type === TEXT_TYPE ? 'mdi-format-text' : null
    this.properties = {
      color: '#000'
    }
    this.boundingBox = {
      x: 0,
      y: 0,
      width: 'initial',
      height: 'initial',
      transform: null
    }
  }

  setBoundingBox(boundingBox = {}) {
    this.boundingBox = {
      ...this.boundingBox,
      ...boundingBox
    }
  }

  setProperties(properties = {}) {
    this.properties = {
      ...this.properties,
      ...properties
    }
  }

  setContent(content = null) {
    this.content = content
  }

  setChildren(children = []) {
    this.children = children
  }

  static getUniqId() {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let autoId = ''
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return autoId
  }
}