<template>
  <div
    style="position: relative;"
    v-if="!onlyContent"
  >
    <div
      class="b-scene__item"
      :style="styles"
      :class="[`b-scene__item-${node.id}`]"
      :id="`b-scene-${node.id}`"
      ref="item"
    >
      <div
        class="b-scene__actions"
      >
        <template
          v-if="!inEditContentProcess"
        >
        <div
          v-for="(action, index) in actions"
          :key="`action-${action.id}`"
          class="b-scene__action"
          :class="{
            'mt-1': index !== 0
          }"
        >
          <v-btn
            dark
            color="primary"
            width="100%"
            class="pa-0"
            min-width="24"
            height="24"
            @click="() => onDbClick(action)"
          >
            <v-icon
              :size="action.size || 18"
            >
              {{ action.icon }}
            </v-icon>
          </v-btn>
        </div>
        </template>
      </div>
      <div
        ref="content"
        v-if="showItem"
        style="overflow: hidden; color: inherit;"
        class="b-scene__item-content font-weight-bold"
      >
        <div
          v-if="!inEditContentProcess"
          ref="text"
          style="color: inherit;"
          :style="{
            width,
            height
          }"
          :id="`text-${node.id}`"
        >
          {{ node.content }}
        </div>
        <textarea
          v-else
          v-model="node.content"
          style="resize: none; color: inherit !important; font-size: inherit; font-family: inherit; font-weight: inherit;"
          ref="content"
          @blur="onBlur"
          @input="onInput"
        />
      </div>
      <div>
        <template
          v-for="child in node.children"
        >
          <BeenokleSceneItem
            :key="child.id"
            :node="child"
            :updated-canvas="updatedCanvas"
            @updateItem="payload => $emit('updateItem', payload)"
            @deleteItem="payload => $emit('deleteItem', payload)"
            :need-rerender-item="needRerenderItem"
          />
        </template>
      </div>
    </div>
    <moveable-component
      :className="[`moveable ${inEditContentProcess ? 'moveable-control-box--in-edit-process' : ''}`]"
      @drag="onDrag"
      @scale="onScale"
      @rotate="onRotate"
      @resize="onResize"
      v-bind="{
        ...$props,
        ...moveableOptions
      }"
    />
  </div>
  <div
    class="b-scene__item"
    v-else
  >
    <div
      v-if="showItem"
      class="b-scene__item-content"
    >
      <span class="caption d-flex align-center justify-center font-weight-bold white--text" style="height: 100%; width: 100%;">{{ node.content }}</span>
    </div>
  </div>
</template>
<script>
import textFit from './helpers/textFit.min.js'
import MoveableComponent from 'vue-moveable'

export default {
  props: {
    needRerenderItem: {},
    autoFitContent: {
      type: Boolean,
      default: () => true
    },
    updatedCanvas: {},
    onlyContent: {
      type: Boolean,
      default: () => false
    },
    node: {
      type: Object,
      required: true
    }
  },
  name: 'BeenokleSceneItem',
  components: {
    MoveableComponent
  },
  data: () => ({
    showItem: true,
    textEl: null,
    inEditContentProcess: false,
    updateTimer: null,
    updateFontTimer: null,
    moveableOptions: null,
    inDragProcess: false,
    inResizeProcess: false,
    bounds: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    }
  }),
  mounted() {
    this.moveableOptions = this.getMoveableOptions()
    this.autoFitContent && this.setFontSize()
  },
  computed: {
    fitOptions() {
      return {
        minFontSize: 6,
        maxFontSize: 100,
        multiLine: true
      }
    },
    actions() {
      let result = [
        {
          id: 'edit',
          icon: 'mdi-pencil'
        },
        {
          id: 'delete',
          icon: 'mdi-close',
          size: 20
        }
      ]
      return result
    },
    moveableContainer() {
      const { moveableContainer } = this.$refs
      return moveableContainer
    },
    properties() {
      return {
        ...this.node.properties
      }
    },
    boundingBox() {
      return this.node.boundingBox
    },
    width() {
      return typeof this.boundingBox.width === 'string' ? 'auto' : this.boundingBox.width + 'px'
    },
    height() {
      return typeof this.boundingBox.height === 'string' ? 'auto' : this.boundingBox.height + 'px'
    },
    styles() {
      const result = {
        width: this.width,
        height: this.height,
        transform: this.boundingBox.transform,
        display: this.showItem ? 'block' : 'none',
        ...this.properties
      }

      return result
    },
    hasChildren() {
      const { children } = this.node
      return children && children.length > 0
    }
  },
  methods: {
    setFontSize() {
      const clearTimer = () => {
        clearInterval(this.updateFontTimer)
        this.updateFontTimer = null
      }
      if (this.updateFontTimer) {
        clearTimer()
      }
      this.updateFontTimer = setTimeout(() => {
        const textEl = document.querySelector(`#text-${this.node.id}`)
        textEl && textFit(textEl, this.fitOptions)
        clearTimer()
      }, 10)
    },
    onInput(e) {
      const { target } = e
      const { scrollHeight } = target
      target.style.height = scrollHeight + 'px'
    },
    onBlur() {

      this.inEditContentProcess = false
      const { content } = this.$refs
      const { height } = content.getBoundingClientRect()

      this.updateItem({
        id: this.node.id,
        name: 'resize',
        data: {
          'boundingBox.height': height
        }
      })

      // this.$nextTick(() => {})

    },
    onDbClick({ id }) {
        switch (id) {
          case 'edit':
            this.inEditContentProcess = true
            this.$nextTick(() => {
              const { content } = this.$refs
              const { scrollHeight } = content
              content.style.height = scrollHeight + 'px'
              content.focus()
            })
            break
          case 'delete':
            this.$emit('deleteItem', this.node)
            break
          default:
            break
        }

      // const { id } = action

    },
    getBounds(container) {
      const { width: right, height: bottom } = container.getBoundingClientRect()
      return {
        top: 0,
        bottom: bottom,
        right: right,
        left: 0
      }
    },
    getGidelines(container) {
      const { width, height } = container.getBoundingClientRect()
      const verticalMiddle = height / 2
      const horizontalMiddle = width / 2
      
      const horizontalGuidelines = [0, verticalMiddle, height]
      const verticalGuidelines = [0, horizontalMiddle, width]

      const result = {
        horizontalGuidelines,
        verticalGuidelines
      }

      return result
    },
    getMoveableOptions() {
      const { id, parentId } = this.node
      const target = document.querySelector(`.b-scene__item-${id}`)
      const containerSelector = `#b-scene-${parentId}`
      const container = document.querySelector(containerSelector)

      const bounds = this.getBounds(container)
      const guidelines = this.getGidelines(container, containerSelector)
      
      //const dragTarget = target && target.querySelector('.b-scene__drag')

      const config = {
        target,
        container,
        ...guidelines,
        snapCenter: true,
        roundable: true,
        snappable: true,
        draggable: true,
        resizable: true,
        rotatable: true,
        zoom: 1,
        throttleResize: 10,
        bounds,
        padding: {"left":0,"top":0,"right":0,"bottom":0},
        dragArea: !this.inEditContentProcess
      }
      return config
    },
    updateItem({
      id = this.node.id,
      name,
      data,
      cb = null
    }) {
      const clearTimer = () => {
        clearInterval(this.updateTimer)
        this.updateTimer = null
      }
      if (this.updateTimer) {
        clearTimer()
      }
      this.updateTimer = setTimeout(() => {
        this.$emit('updateItem', {
          id,
          data
        })
        clearTimer()
        console.log(`UPDATED: ${name}!`)
        cb && cb()

      }, 500)
    },
    onResize(e) {
      this.inResizeProcess = true
      const { content } = this.$refs
      const { width, height, target } = e
      let calculatedHeight = height

      const cb = () => {
        if (!this.autoFitContent && content.scrollHeight > height) {
          calculatedHeight =  height + (content.scrollHeight - height)
        } else {
          calculatedHeight = height
        }

        target.style.width = width + 'px'
        target.style.height = calculatedHeight + 'px'
        this.autoFitContent && this.setFontSize()
      }

      cb()

      this.updateItem({
        name: 'resize',
        data: {
          'boundingBox.width': width,
          'boundingBox.height': calculatedHeight
        },
        cb: () => {
          cb()
          this.inResizeProcess = false
        }
      })
    },
    onDrag(e) {
      this.inDragProcess = true
      const { left: x, top: y } = e
      e.target.style.transform = e.transform

      this.updateItem({
        name: 'drag',
        data: {
          'boundingBox.x': x,
          'boundingBox.y': y,
          'boundingBox.transform': e.transform
        }
      })
      this.inDragProcess = false
    },
    onScale({ drag }) {
      drag
      //console.log(drag)
    },
    onRotate({ drag }) {
      const { transform } = drag

      drag.target.style.transform = transform
      this.updateItem({
        name: 'rotate',
        data: {
          'boundingBox.transform': transform
        }
      })
    },
  },
  watch: {
    needRerenderItem(to) {
      console.log(to, this.node.id)
      if (to && to === this.node.id) {
        this.showItem = false
        this.$nextTick(() => {
          this.showItem = true
        })
      }
    },
    updatedCanvas(to) {
      if (to) {
        this.moveableOptions = this.getMoveableOptions()
      }
    }
  }
}
</script>
<style lang="scss">
.b-scene__drag {
  cursor: pointer;
  position: absolute;
  right: -32px;
  top: -32px;
  z-index: 10000;
}

.b-scene__actions {
  position: absolute;
  right: -15px;
  width: 24px;
  transform: translateX(100%);
  top: 0px;
  background: transparent;
  z-index: 10000;
}

.b-scene__item {

  &-content {
    height: 100%;
  }

  &--in-edit-process {

  }

  &:not(.b-scene__item-canvas) {
    position: absolute;
  }

  &-children {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  &-canvas {
    outline: none !important;
  }

  &-inner {

  }

  #moveable-container-canvas {
    height: 100%;
  }

}
</style>