<template>
  <div
    class="b-scene"
  >
    <div
      class="b-scene__container"
    >

      <div
        class="b-scene__canvas"
        id="b-scene-canvas"
        ref="canvas"
        :style="canvasStyles"
      >
        <div
          style="position: relative; width: 100%; height: 100%;"
        >
          <beenokle-scene-item
            v-if="tempItem"
            ref="temp-item"
            only-content
            :node="tempItem"
            style="width: auto; height: auto;"
          />
          <beenokle-scene-item
            v-for="rootChildren in model[0].children"
            :key="`root-children-${rootChildren.id}`"
            :node="rootChildren"
            :updated-canvas="updatedCanvas"
            :need-rerender-item="needRerenderItem"
            @updateItem="onUpdateItem"
            @deleteItem="onDeleteItem"
          ></beenokle-scene-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BeenokleSceneItem from './item'
import {  
  validURL, 
  getColor,
  getContrast,
  rgbToHex,
  getPalette
} from '@/api/functions'

getPalette

import SceneItem from './classes/item'

const findInTree = (element, id) => {
  if(element.id == id){
    return element
  }else if (element.children && element.children.length){
    let i
    let result = null
    for(i=0; result == null && i < element.children.length; i++){
      result = findInTree(element.children[i], id)
    }
    return result
  }
}

const DEFAULT_CANVAS = {
  id: 'canvas',
  children: [],
  draggable: false,
  boundingBox: {
    width: 0,
    height: 0,
    x: 0,
    y: 0
  },
  background: "#fff"
}

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  name: 'BeenokleScene',
  components: {
    BeenokleSceneItem
  },
  data: () => ({
    needRerenderItem: null,
    updatedCanvas: false,
    tempItem: null,
    inAddItemProcess: false,
  }),
  created() {
    this.$emit('inited', this)
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    canvasData() {
      const data = this.model.find(item => item.id === 'canvas')
      return data || DEFAULT_CANVAS
    },
    canvasStyles() {
      const { width: w, height: h, x, y } = this.canvasData.boundingBox
      const backgroundIsImageUrl = validURL(this.canvasData.background)
      backgroundIsImageUrl
      const background = `url(${this.canvasData.background})`
      const width = w ? w + 'px' : '100%'
      const height = h ? h + 'px' : '100%'

      const left = x ? x + 'px' : '50%'
      const top = y ? y + 'px' : '50%'
      const transform = `translate(${!x ? '-50%' : '0px'}, ${!y ? '-50%' : '0px'})`

      const result = {
        background,
        width,
        height,
        left,
        top,
        transform
      }
      
      return result
    }
  },
  methods: {
    canvasRef() {
      const { canvas } = this.$refs
      return canvas || null
    },
    addItem(parentId = 'canvas', type = 'TEXT') {
      if (this.inAddItemProcess) return
      this.inAddItemProcess = true
      const item = new SceneItem({
        type,
        parentId,
        content: '«Вся жизнь — это поездка с Мурадом, который тебе охуенно сделает, а ты в ней таксист»'
      })

      this.tempItem = item
      this.$nextTick(async () => {
        const { width: canvasWidth, height: canvasHeight } = this.canvasData.boundingBox

        const defaultWidth = canvasWidth / 100 * 75
        const defaultHeight = canvasHeight / 100 * 35
        const defaultX = (canvasWidth / 2) - (defaultWidth / 2)
        const defaultY = (canvasHeight / 2) - (defaultHeight / 2)

        const averageColor = await getColor(this.canvasData.background)
        const hex = rgbToHex(...averageColor)
        const color = getContrast(hex)

        item.setBoundingBox({
          width: defaultWidth,
          height: defaultHeight,
          x: defaultX,
          y: defaultY,
          transform: `translate3d(${defaultX}px, ${defaultY}px, 0)`
        })

        item.setProperties({
          color
        })

        this.tempItem = null
        
        const parent = findInTree(this.model[0], parentId) || this.model[0]
      
        parent.children.push(item)
        this.inAddItemProcess = false
      })
    },
    updateItem(itemId = 'canvas', payload = {}) {
      const entries = Object.entries(payload)
      const finded = this.model.find(item => item.id === itemId)
      if (!finded) return

      const setToValue = (obj, path, value) => {
        var i
        path = path.split('.')
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]]

        obj[path[i]] = value
      }

      entries.forEach(([path, value]) => setToValue(finded, path, value))
    },
    onUpdateItem(payload) {
      const { id, data } = payload
      this.updateItem(id, data)
    },
    onDeleteItem({ id }) {
      const index = this.model.findIndex(i => i.id === id)
      this.model.splice(index, 1)
    }
  },
  watch: {
    canvasStyles() {
      this.updatedCanvas = false
      this.$nextTick(() => {
        this.updatedCanvas = true
      })
    }
  }
}
</script>
<style lang="scss">

.b-scene {
  width: 100%;
  height: 100%;

  .moveable-control-box,
  .b-scene__actions {
    opacity: 0;
  }

  &:hover .moveable-control-box,
  &:hover .b-scene__actions {
    opacity: 1;
  }


  .moveable-control-box--in-edit-process {
    opacity: 0 !important;
  }

  &__container {
    position: relative;
  }

  &__canvas {
    position: absolute;
    border: 0px !important;
    outline: none !important;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 8px;
  }

  &__container,
  &__canvas {
    width: 100%;
    height: 100%;
  }
}

</style>